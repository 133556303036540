import { useQuery } from '@apollo/client';
import React, { createContext, ReactNode, useContext, useMemo } from 'react';
import queryString from 'querystring';
import { pickBy } from 'lodash';
import query_smeStore from 'graphql/queries/query_smeStore';
import query_agencyGetSme from 'graphql/queries/query_agencyGetSme';
interface FullfillmentProviderProps {
    children: ReactNode
}

interface FullfillmentContextProps {
    optionsStore?: any[],
    loadingStores?: boolean,
    optionsChannel?: any[],
    variablesQuery?: {
        channel_codes?: string,
        store_ids?: string,
        from?: number,
        to?: number,
        type?: string
    },
    optionSmes?: any[]
}

const FullfillmentContext = createContext<FullfillmentContextProps>({});

export function useFullfillmentContext() {
    return useContext(FullfillmentContext);
};

export function FullfillmentProvider({ children }: FullfillmentProviderProps) {
    const params = queryString.parse(location.search.slice(1, 100000)) as any;

    const { data: dataStores, loading: loadingStores } = useQuery(query_smeStore, {
        fetchPolicy: 'cache-and-network'
    });

    const { data: dataSmes } = useQuery(query_agencyGetSme, {
        fetchPolicy: 'cache-and-network'
    })
    const optionsChannel = useMemo(() => {
        return dataStores?.op_connector_channels?.map(channel => ({
            ...channel,
            value: channel?.code,
            label: channel?.name
        }))
    }, [dataStores]);

    const optionSmes = useMemo(() => {
        return dataSmes?.agencyGetSme?.map(sme => ({
            ...sme,
            value: sme?.sme_id,
            label: sme?.full_name
        }))
    }, [dataSmes]);

    const optionsStore = useMemo(() => {
        const stores = dataStores?.scAgencySaleStores?.data?.map(store => {
            const channel = dataStores?.op_connector_channels?.find(cn => cn?.code == store?.connector_channel_code);
            return {
                ...store,
                channel,
                value: store?.id,
                label: store?.name
            }
        })
        if (params?.smes) {
            return stores?.filter(store => params?.smes?.includes(store?.sme_id))
        }
        return stores || []
    }, [dataStores, params?.smes]);

    const stores = useMemo(() => {
        if (params?.store_ids) {
            return params?.store_ids
        }
        if (params?.smes) {
            return optionsStore?.map(item => item?.id)?.toString()
        }
        return null
    }, [optionsStore, params?.smes, params?.store_ids])

    const variablesQuery = useMemo(() => {
        const [from, to] = [
            Number(params?.from) || null,
            Number(params?.to) || null,
        ];

        const variables = {
            channel_codes: params?.channel_codes || null,
            store_ids: stores,
            type: params?.type || 'hours',
            source: params?.sources || '',
            last_type: params?.type_filter || 'today',
            from,
            to,
        }

        return pickBy(variables, value => !!value)
    }, [params]);

    const value = useMemo(() => {
        return {
            optionsStore, optionsChannel, variablesQuery, optionSmes, loadingStores
        }
    }, [optionsStore, loadingStores, optionsChannel, variablesQuery, optionSmes]);

    return (
        <FullfillmentContext.Provider value={value}>
            {children}
        </FullfillmentContext.Provider>
    )
}