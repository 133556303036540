import React, { memo, useLayoutEffect, useMemo } from "react";
import { useLayoutContext } from "app/contexts/LayoutContext";
import { Helmet } from "react-helmet-async";
import { Button, Card, Flex, Tooltip, Typography, Tabs, Spin } from "antd";
import { useFullfillmentContext } from "app/contexts/FullfillmentContext";
import { OperationWrapper } from "../Fullfillment.style";
import FullfillmentFilter from "../components/FullfillmentFilter";
import classNames from "classnames";
import FullfillmentOverview from "../components/FullfillmentOverview";
import FullfillmentDetailTable from "../components/FullfillmentDetailTable";
import { TABS } from "../FullfillmentConstants";
import SLAOverview from "../components/SLAOverview";
import SLATable from "../components/SLATable";
import queryString from 'querystring';
import { useQuery } from "@apollo/client";
import dayjs from "dayjs";
import query_scGetOperationFulfillmentTracking from "graphql/queries/query_scGetOperationFulfillmentTracking";
import { useNavigate } from "react-router-dom";
import query_scGetOperationFulfillmentSummarySLA from "graphql/queries/query_scGetOperationFulfillmentSummarySLA";

const { Text } = Typography;

const ReportOverview = () => {
    const params = queryString.parse(location.search.slice(1, 100000)) as any;
    const navigate = useNavigate();

    const { optionsStore, loadingStores } = useFullfillmentContext();
    const connector_channel_code = useMemo(() => {
        if (params?.channel_codes) {
            return params?.channel_codes?.split(',')
        }
        return null
    }, [params?.channel_codes])

    const fulfillment_by = useMemo(() => {
        if (params?.services && params?.services != 3) {
            return params?.services?.split(',')?.map(item => +item)
        }
        if (params?.services == 3) {
            return []
        }
        return [2]
    }, [params?.services])

    const list_source = useMemo(() => {
        if (params?.sources) {
            return params?.sources?.split(',')
        }
        return ['platform', 'manual', 'pos', 'web']
    }, [params?.sources])

    const list_store = useMemo(() => {
        if (params?.store_ids) {
            return params?.store_ids?.split(',')?.map(item => +item)
        }
        if (params?.smes) {
            return optionsStore?.map(item => item?.id)
        }
        return null
    }, [params?.store_ids, params?.smes, optionsStore])

    console.log(list_store)

    const range_time = useMemo(() => {
        if (params?.from && params?.to) {
            return [
                Number(params?.from),
                Number(params?.to),
            ]
        }
        return [
            dayjs(dayjs().subtract(6, 'day')).startOf('day').unix(),
            dayjs().endOf('day').unix(),
        ]
    }, [params?.from, params?.to])

    const variables = useMemo(() => {
        return {
            filter: {
                range_time, list_source, list_store, fulfillment_by, connector_channel_code
            }
        }
    }, [range_time, list_source, list_store, fulfillment_by, connector_channel_code])

    const { data, loading } = useQuery(query_scGetOperationFulfillmentTracking, {
        variables,
        fetchPolicy: 'no-cache',
        skip: params?.tab == "SLA"
    })

    const { data: dataSLA, loading: loadingSLA } = useQuery(query_scGetOperationFulfillmentSummarySLA, {
        variables,
        fetchPolicy: 'no-cache',
        skip: params?.tab == "overview" || !params?.tab
    })

    console.log(dataSLA)
    const { appendBreadcrumb } = useLayoutContext();
    const { variablesQuery } = useFullfillmentContext();
    useLayoutEffect(() => {
        appendBreadcrumb([
            {
                title: 'Xử lý xuất',
                pathname: '/fullfillment-manage',
            },
            {
                title: 'Tổng quan',
                pathname: '/fullfillment-manage/operation',
            },
        ]);
    }, []);
    const onChange = (key: string) => {
        navigate(`/fullfillment-manage/operation?${queryString.stringify({ tab: key }).replaceAll('%2C', '\,')}`);
    };

    return <OperationWrapper>
        <Helmet
            titleTemplate="Theo dõi vận hành - UpBase"
            defaultTitle="Theo dõi vận hành - UpBase"
        >
            <meta name="description" content="Theo dõi vận hành - UpBase" />
        </Helmet>
        {/* <Tabs
            style={{ marginTop: 12, marginLeft: 12 }}
            onChange={onChange}
            items={TABS}
        /> */}
        <Tabs defaultActiveKey={params?.tab ? params?.tab : 'overview'}
            style={{ marginLeft: 12 }}
            className="transparent-tabs"
            items={[{
                label: 'Tổng quan',
                key: 'overview'
            },
            {
                label: 'Theo hạn SLA',
                key: 'SLA'
            }]}
            onChange={onChange} />
        <Flex vertical gap={20}>
            <Card className="card-filter">
                <FullfillmentFilter
                    classNameFilter={classNames('filter-report-fixed-top')}
                    baseRoute="/fullfillment-manage/operation"
                    previousDay={6}
                    showHours
                />
            </Card>
            {(params?.tab == 'overview' || !params?.tab) && <>
                <Card title="Tổng quan" loading={loading || loadingStores}>
                    <FullfillmentOverview data={data?.scGetOperationFulfillmentTracking} />
                </Card>
                <Card title='Báo cáo chi tiết' loading={loading || loadingStores}>
                    <FullfillmentDetailTable data={data?.scGetOperationFulfillmentTracking} range_time={range_time} list_source={list_source} fulfillment_provider_type={fulfillment_by[0]} />
                </Card></>}
            {params?.tab == 'SLA' && <><Card title="Tổng quan" loading={loadingSLA}>
                <SLAOverview data={dataSLA?.scGetOperationFulfillmentSummarySLA} />
            </Card>
                <Card title="Báo cáo đơn còn hạn" loading={loadingSLA}>
                    <SLATable type='in-time' variables={variables} title="N là hạn xử lý còn lại của đơn hàng" data={dataSLA?.scGetOperationFulfillmentSummarySLA} />
                </Card>
                <Card title="Báo cáo đơn hàng trễ" loading={loadingSLA}>
                    <SLATable type='out-time' variables={variables} title="M là độ trễ của đơn hàng so với hạn SLA" data={dataSLA?.scGetOperationFulfillmentSummarySLA} />
                </Card></>}
        </Flex>
    </OperationWrapper>
};

export default ReportOverview;